.formContainer {
  // TODO: use nike design tokens
  margin-bottom: 24px;
  width: 100%;
}

.disabled {
  input,
  textarea {
    color: #111;
  }
}

.short {
  width: fit-content;
  float: left;
}

.showContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  margin: 24px auto;

  label {
    margin-bottom: var(--eds-space-16);
  }
}

.hideContent {
  display: none;
}
