.inputField {
  input {
    &:disabled {
      color: #959595 !important;
    }
    &:focus {
      border-color: var(--eds-color-border-active);
    }
  }
}
