.btns {
  display: flex;
  justify-content: flex-end;

  // TODO: use nike design tokens
  margin-top: 20px;

  & button {
    margin-left: 24px;
  }
}