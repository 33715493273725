.answerOptionWrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .answerFieldsWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 24px;
    width: 85%;
  }

  > div > div:first-child {
    width: 100%;
    margin-right: 24px;
    > label > span {
      display: none;
    }
  }

  > label,
  > button {
    margin: 12px 20px 0px 0px;
    &:hover {
      cursor: pointer;
    }

    &[disabled] {
      color: gray;
      max-width: 24px;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  > button {
    margin-right: 0px;
  }

  > label.eds-toggle--disabled {
    background: black;
    color: #ededee !important;
  }

  > div > div {
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      flex: 1 0 55%;
    }

    &:last-child {
      flex: 1 0 45%;
    }

    > div {
      width: 100%;
    }
  }

  .eds-label {
    display: none;
  }
}

.buttonStyle {
  margin-top: 20px;
  cursor: pointer;
  background: transparent;
  padding: 6px 20px;
  border: 1px solid #cacacb;
  &[disabled] {
    cursor: not-allowed;
  }
}

.textFieldStyle {
  :focus {
    border-color: #707072 !important;
  }
  :disabled {
    border: 0;
  }
}

.errorMessage {
  color: var(--eds-color-brand-red);
  margin: 10px 0px;
}

.error {
  font: var(--eds-type-subtitle-2) !important;
  margin-top: var(--eds-space-8);
  color: var(--eds-color-red-60);
}
