.formContainer {
  // TODO: use nike design tokens
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--eds-space-16);
}
.short {
  width: fit-content;
  float: left;
}
