.errorText {
  color: var(--eds-color-text-danger);
  font: var(--eds-type-body-3);
  margin-left: -14px;
}

.helperText {
  margin-left: -14px;
  margin-top: 4px;
  color: var(--eds-color-text-secondary);
}
