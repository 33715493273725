.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  margin: 16px 0;
  p {
    margin: 5px;
  }
  .header {
    display: flex;
    flex: 1;
    align-items: center;
    max-height: fit-content;
  }

  .lightGray {
    color: gray;
  }
  .bold {
    font-weight: bold;
  }
  @media (max-width: 960px) {
    grid-template-columns: repeat(1, 1fr);
  }
}