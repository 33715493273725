/**
  CONSOLE BASE STYLES VARIABLES
*/

/* Breakpoints */
$xs: 375px;
$sm: 600px;
$md: 768px;
$lg: 1024px;

/* Colors */

// Nike default
$white: #FFFFFF;
$nearWhite: #F7F7F7;
$ultraLightGrey: #E5E5E5;
$lightGrey: #DDDDDD;
$defaultGrey: #CCCCCC;
$mediumLightGrey: #999999;
$mediumGrey: #666666;
$grey: #444444;
$darkGrey: #222222;
$nearBlack: #111111;
$navGrey: #8D8D8D;
$black: #000000;


// Nike Evergreen

$challengeRed: #A62732;
$fusionRed: #B23D56;
$errorRed: #FE0000;
$teamOrange: #E5482C;
$nikeOrange: #FA5400;
$volt: #E7FF00;
$nikeFuel: #87E300;
$nikePlusCyan: #00A4E4;
$photoBlue: #0075BA;
$valiantBlue: #2382D1;
$gameRoyal: #135291;
$courtPurple: #40376C;

// Workshop Colors

$green: #09C217;


// Console Specific
$violet: #8d79f4;
$green: #0efe22;
$creamsicle: #f9a176;

// Stage Colors
$explore: $white;
$discover: $nikePlusCyan;
$generate: $violet;
$validate: $volt;
$pilot: $nikeFuel;
$transition: $creamsicle;
$scale: $nikeOrange;

// Timing

$hoverTiming: 250ms;

@mixin absolutePositionCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


:export {
  teamOrange: $teamOrange;
  photoBlue: $photoBlue;
  volt: $volt;
  nikePlusCyan: $nikePlusCyan;
  nikeOrange: $nikeOrange;
  white: $white;
  nikeFuel: $nikeFuel;
  explore: $explore;
  discover: $discover;
  generate: $generate;
  validate: $validate;
  pilot: $pilot;
  transition: $transition;
  scale: $scale;
}