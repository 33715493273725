.btns {
  display: flex;
  justify-content: flex-end;

  margin-top: 20px;
  button {
    margin-left: 8px;
  }
}

.ppiGroupWrapper {
  display: flex;
  align-items: center;
  margin: 24px 0px;

  .ppiGroup {
    display: flex;
    border: 1px solid #111111;
    padding: 12px;
    border-radius: 8px;
    width: 100%;

    .ppiProperty {
      width: 40%;
      margin-right: 4px;
    }

    .ppiOperator {
      width: 20%;
      margin-right: 4px;
    }

    .ppiValue {
      width: 40%;
    }
  }

  button {
    margin-left: 5px;
  }
}

.addButton {
  margin-top: 16px;
}

.addIdsButton {
  margin-bottom: 16px;
}

.greyOut {
  background-color: #dedede !important;
}

.gridRow {
  width: 100%;
}

.gridColumn {
  width: 50%;
}

.title {
  border-bottom: 1px solid #ccc;
  margin: 0px;
  padding: 0px;
}

.greyOut {
  input {
    color: #959595;
  }
}

.parentTabGroupContainer {
  width: 100%;
  padding: 0 8px;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: var(--eds-space-8);
  }
}

.addPrefTabGroup {
  margin: var(--eds-space-16) 0;
  justify-content: space-around;

  li {
    width: 50%;
    text-align: center;
    background-color: #dfdfdf;
    padding: var(--eds-space-8) 0 0 0;
  }
}
