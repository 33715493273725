@import 'variables';

.wrapper {
  padding-top: 10px;
  height: 100%;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.searchField {
  margin: 20px 20px 20px 0px;
}

.searchWrapper {
  display: flex;
  align-items: center;
}

.searchIcon {
  margin-right: 10px;
}
