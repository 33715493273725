@import 'variables';

.wrapper {
  padding-top: 10px;
  height: 100%;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}