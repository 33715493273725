.preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;

  section {
    padding: 24px 32px;
    background-color: #fff;
    margin: 8px 24px;
    overflow-x: scroll;

    a {
      cursor: pointer;
      color: var(--eds-color-text-link) !important;
      :hover,
      :active {
        color: var(--eds-color-text-link-visited);
      }
    }
  }
  p,
  li {
    font: var(--eds-type-body-3);
    margin: 10px;
  }
  .title {
    margin-bottom: 32px !important;
  }
  .text {
    display: flex;
    margin-bottom: 16px;
    margin-top: 24px;
    h1 {
      font: normal normal 500 36px/40px 'Helvetica Neue', Helvetica, Arial,
        sans-serif !important;
    }
    h2 {
      font: normal normal 500 26px/30px 'Helvetica Neue', Helvetica, Arial,
        sans-serif !important;
    }
    h3 {
      font: normal normal 500 18px/22px 'Helvetica Neue', Helvetica, Arial,
        sans-serif !important;
    }
  }
  table {
    width: 100%;
  }
  td > * {
    font: var(--eds-type-subtitle-2);
  }
  .container {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ul {
    margin-left: 16px;
    list-style-type: disc;
  }
  ol {
    padding-left: 0;
    margin-left: 16px;
    list-style-type: decimal;
  }
  .blockquote {
    blockquote {
      border-left: 5px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;

      p {
        display: inline;
      }
    }
  }
}

.highlight {
  background-color: #b3e7ff;
  width: max-content;
}
