.preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
}

.title {
  margin-bottom: 16px !important;
}

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  justify-items: flex-end;

  //Makes action buttons of same width
  button {
    min-width: 100px;

    &:not(:only-child):first-child {
      margin-right: 10px;
    }
  }
}

.assetLinks {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table {
  width: 100%;
}

.assetCard {
  display: flex;
  min-height: 100%;
  max-width: 100%;
  flex-direction: column;
}

.noContent {
  margin: 56px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
