.menuEllipsis {
  position: absolute;
  right: 45px;
  top: 23px;
  z-index: 1;
}

.arrowsContainer {
  display: flex;
  position: absolute;
  top: 6px;
  left: -26px;
}

.actionBtnDisabled {
  cursor: not-allowed;
  color: var(--eds-color-text-secondary);
}

.actionBtnEnabled {
  cursor: pointer;
}
