.assetContainer {
  display: flex;
  margin-bottom: 40px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .icon {
    display: flex;
    align-items: center;
    text-decoration: none !important;
  }

  svg {
    margin-right: 5px;
  }

  .fieldName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.clickableRow {
  cursor: pointer;
}
