// @font-face {font-family:"Nike TG";font-style:normal;font-weight:normal;src:url(../../fonts/Nike-TG.woff2) format('woff2'), url(../../fonts/Nike-TG.woff) format('woff'), url(../../fonts/Nike-TG.ttf) format('truetype');}@font-face {font-family:"Nike Futura";font-style:normal;font-weight:normal;src:url(../../fonts/Nike-Futura.woff2) format('woff2'), url(../../fonts/Nike-Futura.woff) format('woff'), url(../../fonts/Nike-Futura.ttf) format('truetype');}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100vh;
  height: 100%;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

p {
  word-break: break-all;
}

.react-toast-notifications__toast__content {
  display: flex;
  align-items: center;
}

.eds-input-message-group {
  .eds-color--text-secondary {
    font-size: 12px;
    letter-spacing: 0.02rem;
  }
}

.eds-select .eds-select__control {
  min-height: 56px !important;
  box-shadow: none;
  &:hover,
  &:focus {
    border-color: var(--eds-color-border-active) !important;
  }
}

.eds-select__control--is-disabled {
  background: var(--eds-color-grey-3) !important;
}

details[data-nr-animatable='true']:not([data-nr-animation=''])[open]
  > *:not(summary) {
  overflow-y: initial !important;
}

.asterisk {
  margin-left: 4px;
}

.hasErrors {
  color: #f44336;
}

.eds-divider {
  background-color: var(--eds-color-grey-2) !important;
}
