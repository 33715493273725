.autoApproved {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  padding: var(--eds-space-16);
  margin-top: 24px;
  width: 100%;
  max-width: 820px;
  background: #ffffff;

  label {
    font-weight: 500 !important;
  }

  > p {
    margin-top: var(--eds-space-16);
  }
}
