.btns {
  display: flex;
  justify-content: flex-end;

  margin-top: 20px;
  button {
    margin-left: 8px;
  }
}

.greyOut {
  background-color: #dedede !important;
}

.gridRow {
  width: 100%;
}

.gridColumn {
  width: 50%;
}

.title {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
