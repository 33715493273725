@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.message {
  letter-spacing: 0.02rem !important;
  margin-top: 4px;
}
