.title {
  border-bottom: 1px solid #ccc;
  margin-bottom: 12px;
  padding: 12px 0 4px;
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 8px;
}

.greyOut {
  input {
    color: #959595;
  }
}
