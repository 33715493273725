.wrapper {
  display: block;
  margin-bottom: var(--eds-space-24);
}

.hideContent {
  display: none;
}

.showContent {
  width: 100%;
  max-width: 550px;
  margin: 0px auto;
}
