.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

fieldset {
  border: none;
}
.fieldSetGrid {
  border-bottom: 1px solid var(--eds-color-grey-2);
  padding-bottom: 10px;
  margin: 12px 0;
}

.greyOut > div > div {
  background-color: #dedede !important;
}
