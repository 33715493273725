.label {
  & h6 {
    margin: 0 0 16px 0;
  }
}

.checkbox {
  margin-bottom: 16px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
}