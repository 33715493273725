// override nacl padding
.radioFieldLabel {
  font-size: 16px;
  input,
  > div {
    padding: 0;
  }
  .eds-radio--disabled,
  label,
  label > svg {
    color: 'unset';
  }
}

.tooltip {
  margin-left: 20%;
  font: normal normal 500 14px/18px 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  padding: 4px;
  margin-top: 12px;
  border: 1px solid rgba(117, 117, 117, 0.5);
  width: fit-content;
  border-radius: 5px;
  position: absolute;
  border-radius: 5px;
  background-color: black;
  color: white;
  box-shadow: 1px 1px 2px #888888;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 42%;
    border-style: solid;
    border-width: 0px 12px 12px 0px;
    border-color: transparent black transparent transparent;
    box-shadow: 1px -1px 1px rgba(117, 117, 117, 0.5);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &.textAndHiddenStyle {
    &::before {
      left: 42%;
    }
  }

  &.hiddenStyle {
    &::before {
      left: 62%;
    }
  }
}

.disabled {
  cursor: not-allowed;
}
