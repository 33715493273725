.dropZone {
    position: relative;

    background-color: #f1f1f1;
    color: black;
    padding: 0;
    text-align: center;
    border: 2px dotted #666666;

    min-height: 200px;

    &:focus {
        outline: none; 
    }

    &:hover .overlay, &.hover .overlay {
        visibility: visible;
        opacity: 1;
    }

    &.occupied {
        border: none;
    }
}

.overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);

    z-index: 100;

    transition: ease-in .25s;
    visibility: hidden;
    opacity: 0;
}


.uploadIcon {
    width: 75px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.preview {
    width: 100%;
}