.label {
  color: #111111;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  font-weight: 500;
  text-align: center;
  span {
    color: rgb(135, 133, 133);
    font-size: 12px;
    line-height: 24px;
    text-align: center;
  }
}

.textField {
  height: 55px;
  width: 100%;
  background: white;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;
  fieldset {
    border-color: white;
  }
}

.startAudi {
  width: 100%;
  margin-right: -8px;
}

.digital-service {
  min-width: 110px;
  max-width: 120px;
  width: 100%;
}

.activity-survey {
  min-width: 110px;
  max-width: 120px;
  width: 100%;
}

.audience {
  min-width: 70px;
  max-width: 80px;
  width: 100%;
}
