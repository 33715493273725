.single-image-upload {
  outline: none !important;
  min-height: 100px;
  display: flex;
  opacity: 0.9;
  background-color: #efefef;
  box-shadow: inset 0px 0px 71px -44px rgba(0, 0, 0, 0.75);
  position: relative;
  &[disabled] {
    background-color: rgba(0, 0, 0, 0.5);
    filter: grayscale(100%);
  }

  .preview-asset,
  .reset-asset {
    position: absolute;
    right: 0;
    top: 0;

    &:focus {
      outline: none;
    }
  }
  .preview-asset {
    pointer-events: all;
  }
  &:active,
  &:focus {
    outline: none !important;
  }
  &:hover {
    opacity: 1;
  }
  .dropzone {
    width: 100%;
    height: 100%;
    &:active,
    &:focus {
      outline: none !important;
    }
    .upload-trigger-button {
      display: flex;
      background-color: transparent !important;
      border: none;
      outline: none;
      background-color: #ccc;
      overflow: hidden;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      max-height: 350px;
      span {
        flex: 1;
        display: flex;
        height: 34px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.11px;
        line-height: 32px;
        position: absolute;
        padding: 0 15px;
        color: white;
      }
      img {
        object-fit: scale-down;
        object-position: 50% 50%;
        flex: 1;
      }
    }
  }
  &.small {
    width: 50%;
    background-color: var(--eds-color-background-default);
  }

  &.xsmall {
    width: 30%;
    margin: auto;
    background-color: var(--eds-color-background-default);
  }
}
.helper-text {
  line-height: 20px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.uploadingMessage {
  color: gray;
}

.errorMessage {
  color: red;
}

.label {
  margin-bottom: 8px;
}
