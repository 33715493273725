.label {
  color: #fff;
  > div {
    width: 100%;
  }
}

.img {
  margin-bottom: 10px;
  display: block;
  max-width: 250px;
  max-height: 250px;
}