.rowContainer {
  display: flex;
  gap: var(--eds-space-8);
  div:first-child {
    flex: 1;
  }
}

.warningPopover {
  position: absolute;
  right: 11%;
  top: 53px;
}

.warningIcon {
  cursor: pointer;
  font: var(--eds-type-title-4);
}

.popoverText {
  width: 450px;
  word-break: break-word;
}

.optionalQuestionToggle {
  position: absolute;
  right: 0;
  top: 0;
}
