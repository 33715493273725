.subTitleMessageStyle {
  margin: 24px 0 12px;
}

.addMessageStyle {
  margin-bottom: 12px;
}

.addExistingMessageStyle {
  margin-top: 24px;
  margin-bottom: 12px;
}

.createAudienceBtn {
  width: fit-content;
}
