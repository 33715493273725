.btns {
  display: flex;
  justify-content: flex-end;

  // TODO: use nike design tokens
  margin-top: 20px;

  & button:last-child {
    margin-left: 24px;
  }
}

.toggleFieldsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--eds-space-24);
  column-gap: var(--eds-space-40);
  margin-top: var(--eds-space-12);
}

.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--eds-space-24);
}

.toggleContainer {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--eds-space-8);
}

.audienceNameStyle {
  color: var(--eds-color-black) !important;
}
