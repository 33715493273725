.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.formSelect {
  display: flex;
  margin-top: 10px;
  .inputLabel {
    top: -5px;
  }
}

.form {
  align-items: flex-start;
  max-width: 600px;
}

fieldset {
  border: none;
}
.fieldSetGrid {
  border-bottom: 1px solid var(--eds-color-grey-2);
  padding-bottom: 10px;
  margin: 12px 0;
}

.checkboxsetWrapper {
  margin-bottom: 16px;
}

.checkboxsetLabel {
  color: #111111;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  font-weight: 500;
  margin-bottom: 16px;
}

.singleCheckbox {
  margin: 20px 20px 20px 5px !important;
}

.greyOut > div > div {
  background-color: #dedede !important;
}

.fullWidth {
  width: 100%;
}
