@import 'variables.scss';
.layout {
  :global(.NavPanelMenuItem-btn) h5 {
    color: #fff;
    margin: 0 !important;
  }
  // todo: do we need these?
  .light {
    svg {
      fill: $black;
      // stroke: $black;
    }
  }

  .dark {
    svg {
      fill: $white;
      // stroke: $white;
    }
  }
}

.override {
  background-color: grey;
  height: 40px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  position: relative;
  z-index: 99;
}

.layoutErrorBoundary {
  height: 100vh;
}
